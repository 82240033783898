import { Typography } from '@mui/material';
import MacroSlider from './MacroSlider';

function MacroRow(props: {
  title: string;
  current: number;
  goal?: number;
  adornment?: string;
}) {
  const {
    title, current, goal, adornment,
  } = props;
  return (
    <tr>
      <td>
        <Typography align="right" variant="body2">
          {title}
        </Typography>
      </td>
      <td style={{ width: '100%', verticalAlign: 'center' }}>
        <MacroSlider current={current} goal={goal} />
      </td>
      <td style={{ textAlign: 'right' }}>
        <strong>{current}</strong>
      </td>
      <td style={{ textAlign: 'left' }}>
        <small>
          /
          {goal}
          {adornment}
        </small>
      </td>
    </tr>
  );
}

export default MacroRow;
