import { useState, useEffect } from 'react';
import { getDoc, doc, setDoc } from 'firebase/firestore';
import cloneDeep from 'lodash.clonedeep';
import { createCollection, auth } from '../firebase';
import DayLog, { FoodItem } from '../types/Log';
import useUserProfile from './useUserProfile';
import UserProfile from '../types/UserProfile';
import { convertToUnit } from '../util/units';

export const calculateCalMacros = (item: FoodItem) => {
  let calories = 0;
  let carbohydrates = 0;
  let protein = 0;
  let fat = 0;
  if (item.amount_unit === 'each') {
    calories = item.amount * item.serving_calories;
    carbohydrates = item.amount * item.serving_carbohydrates;
    protein = item.amount * item.serving_protein;
    fat = item.amount * item.serving_fat;
  } else if (item.amount_unit === item.serving_size_unit) {
    calories = item.serving_calories * (item.amount / item.serving_size);
    carbohydrates = item.serving_carbohydrates * (item.amount / item.serving_size);
    protein = item.serving_protein * (item.amount / item.serving_size);
    fat = item.serving_fat * (item.amount / item.serving_size);
  } else {
    calories = item.serving_calories
      * (convertToUnit(item.amount, item.amount_unit, item.serving_size_unit) / item.serving_size);
    carbohydrates = item.serving_carbohydrates
      * (convertToUnit(item.amount, item.amount_unit, item.serving_size_unit) / item.serving_size);
    protein = item.serving_protein
      * (convertToUnit(item.amount, item.amount_unit, item.serving_size_unit) / item.serving_size);
    fat = item.serving_fat
      * (convertToUnit(item.amount, item.amount_unit, item.serving_size_unit) / item.serving_size);
  }
  return {
    calories: Math.round(calories),
    carbohydrates: Math.round(carbohydrates),
    protein: Math.round(protein),
    fat: Math.round(fat),
  };
};

export default function useLog(date: string) {
  const [log, setLog] = useState<DayLog>();
  const [currentDate, setCurrentDate] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const { data: userProfile } = useUserProfile();
  const user = auth.currentUser;

  const generateEmptyDay = (profile: UserProfile) => ({
    total_calories: 0,
    total_carbohydrates: 0,
    total_fat: 0,
    total_protein: 0,
    target_calories: profile.target_calories,
    target_carbohydrates: profile.target_carbohydrates,
    target_fat: profile.target_fat,
    target_protein: profile.target_protein,
    meals: profile.default_meals.map((meal) => ({
      name: meal,
      items: [],
      meal_calories: 0,
      meal_protein: 0,
      meal_carbohydrates: 0,
      meal_fat: 0,
    })),
  });

  const saveLog = (newLog: DayLog) => {
    if (user) {
      const collectionRef = createCollection<DayLog>(
        `/food_logs/${user.uid}/log`,
      );
      const ref = doc(collectionRef, date);
      setDoc(ref, JSON.parse(JSON.stringify(newLog)));
    }
  };

  const updateTotals = (current: DayLog) => {
    if (current) {
      const newLog = cloneDeep(current);
      newLog.total_calories = 0;
      newLog.total_carbohydrates = 0;
      newLog.total_fat = 0;
      newLog.total_protein = 0;
      newLog.meals = newLog.meals.map((meal) => {
        const newMeal = cloneDeep(meal);
        newMeal.meal_calories = 0;
        newMeal.meal_carbohydrates = 0;
        newMeal.meal_protein = 0;
        newMeal.meal_fat = 0;
        meal.items.forEach((item) => {
          const {
            calories,
            carbohydrates,
            protein,
            fat,
          } = calculateCalMacros(item);
          newMeal.meal_calories
            += calories;
          newMeal.meal_carbohydrates
            += carbohydrates;
          newMeal.meal_protein
            += protein;
          newMeal.meal_fat
            += fat;
        });
        newLog.total_calories += newMeal.meal_calories;
        newLog.total_carbohydrates += newMeal.meal_carbohydrates;
        newLog.total_protein += newMeal.meal_protein;
        newLog.total_fat += newMeal.meal_fat;
        return newMeal;
      });
      // save doc
      saveLog(newLog);
      return newLog;
    }
    return current;
  };

  const addItem = (meal: string, item: FoodItem) => {
    setLog((oldLog) => {
      if (oldLog) {
        // loop through meals
        return updateTotals({
          ...oldLog,
          meals: oldLog.meals.map((oldMeal) => {
            if (oldMeal.name === meal) {
              oldMeal.items.push(item);
            }
            return oldMeal;
          }),
        });
      }
      return oldLog;
    });
  };

  const updateLog = (newLog: DayLog) => {
    setLog((oldLog) => {
      if (oldLog) {
        // loop through meals
        return updateTotals({
          ...newLog,
        });
      }
      return oldLog;
    });
  };

  const deleteItem = (mealName: string, index: number) => {
    setLog((oldLog) => {
      if (oldLog) {
        // loop through meals
        return updateTotals({
          ...oldLog,
          meals: oldLog.meals.map((oldMeal) => {
            if (oldMeal.name === mealName) {
              oldMeal.items.splice(index, 1);
            }
            return oldMeal;
          }),
        });
      }
      return oldLog;
    });
  };

  const updateItem = (meal: string, index: number, item: FoodItem) => {
    setLog((oldLog) => {
      if (oldLog) {
        // loop through meals
        return updateTotals({
          ...oldLog,
          meals: oldLog.meals.map((oldMeal) => {
            if (oldMeal.name === meal) {
              const newMeal = cloneDeep(oldMeal);
              newMeal.items[index] = item;
              return newMeal;
            }
            return oldMeal;
          }),
        });
      }
      return oldLog;
    });
  };

  useEffect(() => {
    if (date !== currentDate) {
      setIsLoading(true);
      setError(false);
      if (user && userProfile) {
        const collectionRef = createCollection<DayLog>(
          `/food_logs/${user.uid}/log`,
        );
        const ref = doc(collectionRef, date);
        getDoc(ref)
          .then((v) => {
            if (v.exists()) {
              setLog(v.data());
            } else {
              setLog(generateEmptyDay(userProfile));
            }
            setCurrentDate(date);
          })
          .catch(() => setError(true));
      }
    }
  }, [user, userProfile, date]);

  return {
    data: log,
    isLoading,
    error,
    addItem,
    deleteItem,
    updateItem,
    updateLog,
  };
}
