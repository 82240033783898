import ConstructionIcon from '@mui/icons-material/Construction';
import Box from '@mui/material/Box';

function Recipes() {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <h1>Calendar coming soon</h1>
      <ConstructionIcon sx={{ width: '2em', height: '2em' }} />
    </Box>
  );
}

export default Recipes;
