import { useState, useEffect } from 'react';
import { getDoc, doc, setDoc } from 'firebase/firestore';
import cloneDeep from 'lodash.clonedeep';
import { createCollection, auth } from '../firebase';
import UserProfile from '../types/UserProfile';

const defaultUserProfile: UserProfile = {
  first_name: '',
  last_name: '',
  default_meals: ['Breakfast', 'Lunch', 'Dinner', 'Snacks'],
  target_calories: 2000,
  target_carbohydrates: 200,
  target_fat: 67,
  target_protein: 150,
};

export default function useUserProfile() {
  const [profile, setUserProfile] = useState<UserProfile>();
  const user = auth.currentUser;

  const saveProfile = (newProfile: UserProfile) => {
    if (user) {
      const collectionRef = createCollection<UserProfile>('users');
      const ref = doc(collectionRef, user.uid);
      setDoc(ref, JSON.parse(JSON.stringify(newProfile)));
    }
  };

  const save = () => {
    if (user) {
      const collectionRef = createCollection<UserProfile>('users');
      const ref = doc(collectionRef, user.uid);
      setDoc(ref, JSON.parse(JSON.stringify(profile)));
    }
  };

  useEffect(() => {
    if (user) {
      const collectionRef = createCollection<UserProfile>('users');
      const ref = doc(collectionRef, user.uid);
      getDoc(ref).then((v) => {
        if (v.exists()) {
          setUserProfile(cloneDeep(v.data()));
        } else {
          setUserProfile(defaultUserProfile);
          saveProfile(defaultUserProfile);
        }
      });
    }
  }, [user]);

  return {
    data: profile,
    updateData: setUserProfile,
    saveProfile,
    save,
  };
}
