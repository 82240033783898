import { useTheme } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import MacroRow from './MacroRow';
import DayLog from '../types/Log';

/*
 * TODO: when sticky div is stuck, shrink the macro rows
 *
 */

type MacroHeaderProps = {
  log: DayLog | undefined;
};

function MacroHeader({ log }: MacroHeaderProps) {
  const theme = useTheme();
  return (
    <div
      style={{
        paddingTop: 0,
        position: 'sticky',
        top: 0,
        backgroundColor: theme.palette.background.default,
        marginLeft: '-2px',
        marginRight: '-2px',
        zIndex: 3,
      }}
    >
      <table style={{ width: '100%', borderSpacing: 0 }}>
        <tbody>
          {log === undefined ? (
            <>
              <tr>
                <td>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </td>
              </tr>
              <tr>
                <td>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </td>
              </tr>
              <tr>
                <td>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </td>
              </tr>
              <tr>
                <td>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </td>
              </tr>
            </>
          ) : (
            <>
              <MacroRow
                title="CAL"
                current={log?.total_calories || 0}
                goal={log?.target_calories}
              />
              <MacroRow
                title="C"
                current={log?.total_carbohydrates || 0}
                goal={log?.target_carbohydrates}
                adornment="g"
              />
              <MacroRow
                title="P"
                current={log?.total_protein || 0}
                goal={log?.target_protein}
                adornment="g"
              />
              <MacroRow
                title="F"
                current={log?.total_fat || 0}
                goal={log?.target_fat}
                adornment="g"
              />
            </>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default MacroHeader;
