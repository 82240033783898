import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import UserProfile from '../types/UserProfile';

type MacrosPercentageSetupProps = {
  data: UserProfile
  updateData: React.Dispatch<React.SetStateAction<UserProfile | undefined>>
};

export default function MacrosPercentageSetup({ data, updateData }: MacrosPercentageSetupProps) {
  const [error, setError] = useState<string | undefined>();
  const [cals, setCals] = useState('');
  const [carbs, setCarbs] = useState('');
  const [protein, setProtein] = useState('');
  const [fat, setFat] = useState('');

  const getGrams = (percentage: string, calPerGram: number) => {
    if (carbs !== '') {
      const grams = Math.round(
        (((parseInt(percentage, 10) / 100) * parseInt(cals, 10)) / calPerGram),
      );
      if (grams) {
        return grams;
      }
      return 0;
    }
    return 0;
  };

  const getPercentage = (calories: number, grams: number, calPerGram: number) => Math.round(
    (((grams * calPerGram) / calories) * 100),
  ).toString();

  useEffect(() => {
    setCals(data.target_calories.toString());
    setCarbs(getPercentage(data.target_calories, data.target_carbohydrates, 4));
    setProtein(getPercentage(data.target_calories, data.target_protein, 4));
    setFat(getPercentage(data.target_calories, data.target_fat, 9));
  }, [data]);

  useEffect(() => {
    const total = parseInt(carbs, 10) + parseInt(protein, 10) + parseInt(fat, 10);
    if (total !== 100) {
      setError('Percentages must add up to 100%');
    } else {
      setError(undefined);
      updateData({
        ...data,
        target_calories: parseInt(cals, 10),
        target_carbohydrates: getGrams(carbs, 4),
        target_protein: getGrams(protein, 4),
        target_fat: getGrams(fat, 9),
      });
    }
  }, [cals, carbs, protein, fat]);

  return (
    <Grid container spacing={2}>
      {error ? (
        <Grid item xs={12}>
          <Typography color="error" variant="body1">
            Error:
            {error}
          </Typography>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <TextField
          fullWidth
          onChange={(e) => setCals(e.target.value)}
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">Cal</InputAdornment>,
          }}
          value={cals}
          label="Target Calories"
        />
      </Grid>
      <Grid item xs={8}>
        <TextField
          fullWidth
          value={carbs}
          type="number"
          onChange={(e) => setCarbs(e.target.value)}
          label="Target Carbohydrates"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body1">
          <strong>{getGrams(carbs, 4)}</strong>
          g
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <TextField
          fullWidth
          value={protein}
          type="number"
          label="Target Protein"
          onChange={(e) => setProtein(e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body1">
          <strong>{getGrams(protein, 4)}</strong>
          g
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <TextField
          fullWidth
          value={fat}
          type="number"
          label="Target Fat"
          onChange={(e) => setFat(e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body1">
          <strong>{getGrams(fat, 9)}</strong>
          g
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}
