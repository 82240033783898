export default function titleCase(str: string) {
  if (str && str.length > 0) {
    return (str || '')
      .toLowerCase()
      .split(' ')
      .map((word) => word.replace(word[0], word[0].toUpperCase()))
      .join(' ');
  }
  return str;
}
