import find from 'lodash.find';

export const MassUnitTypes = [
  {
    key: 'mg',
    label: 'mg',
    toStandard: (val: number) => val / 1000000,
    fromStandard: (val: number) => val * 1000000,
  },
  {
    key: 'g',
    label: 'g',
    toStandard: (val: number) => val / 1000,
    fromStandard: (val: number) => val * 1000,
  },
  {
    key: 'kg',
    label: 'kg',
    toStandard: (val: number) => val,
    fromStandard: (val: number) => val,
  },
  {
    key: 'lb',
    label: 'lb',
    toStandard: (val: number) => val / 2.20462,
    fromStandard: (val: number) => val * 2.20462,
  },
  {
    key: 'oz',
    label: 'oz',
    toStandard: (val: number) => val / 35.274,
    fromStandard: (val: number) => val * 35.274,
  },
];

export const VolumeUnitTypes = [
  {
    key: 'ml',
    label: 'ml',
    toStandard: (val: number) => val / 1000,
    fromStandard: (val: number) => val * 1000,
  },
  {
    key: 'l',
    label: 'l',
    toStandard: (val: number) => val,
    fromStandard: (val: number) => val,
  },
  {
    key: 'tsp',
    label: 'tsp',
    toStandard: (val: number) => val / 202.88413621,
    fromStandard: (val: number) => val * 202.88413621,
  },
  {
    key: 'tbsp',
    label: 'tbsp',
    toStandard: (val: number) => val / 67.628045404,
    fromStandard: (val: number) => val * 67.628045404,
  },
  {
    key: 'floz',
    label: 'floz',
    toStandard: (val: number) => val / 33.814022702,
    fromStandard: (val: number) => val * 33.814022702,
  },
  {
    key: 'cup',
    label: 'cup',
    toStandard: (val: number) => val / 4.2267528377,
    fromStandard: (val: number) => val * 4.2267528377,
  },
  {
    key: 'pt',
    label: 'pt',
    toStandard: (val: number) => val / 2.1133764189,
    fromStandard: (val: number) => val * 2.1133764189,
  },
  {
    key: 'qt',
    label: 'qt',
    toStandard: (val: number) => val / 1.0566882094,
    fromStandard: (val: number) => val * 1.0566882094,
  },
  {
    key: 'gal',
    label: 'gal',
    toStandard: (val: number) => val / 0.2641720524,
    fromStandard: (val: number) => val * 0.2641720524,
  },
  {
    key: 'bbl',
    label: 'bbl',
    toStandard: (val: number) => val / 0.0083864144,
    fromStandard: (val: number) => val * 0.0083864144,
  },
  {
    key: 'ifloz',
    label: 'ifloz',
    toStandard: (val: number) => val / 35.195079728,
    fromStandard: (val: number) => val * 35.195079728,
  },
  {
    key: 'ipt',
    label: 'ipt',
    toStandard: (val: number) => val / 1.7597539864,
    fromStandard: (val: number) => val * 1.7597539864,
  },
  {
    key: 'iqt',
    label: 'iqt',
    toStandard: (val: number) => val / 0.8798769932,
    fromStandard: (val: number) => val * 0.8798769932,
  },
  {
    key: 'igal',
    label: 'igal',
    toStandard: (val: number) => val / 0.2199692483,
    fromStandard: (val: number) => val * 0.2199692483,
  },
  {
    key: 'ibbl',
    label: 'ibbl',
    toStandard: (val: number) => val / 0.0061102569,
    fromStandard: (val: number) => val * 0.0061102569,
  },
];

export const AllUnits = [...MassUnitTypes, ...VolumeUnitTypes];

export function unitToString(
  value?: string | number,
  unit?: string,
  separator = '',
) {
  if (value) {
    const unitString = AllUnits.find((item) => item.key === unit)?.label;
    if (unitString) {
      return value + separator + unitString;
    }
    return value + separator;
  }
  return '';
}

export function getUnitString(unit: string) {
  return AllUnits.find((item) => item.key === unit)?.label;
}

export function toStandard(
  value: string | number,
  unit: (typeof AllUnits)[number]['key'],
) {
  const unitDesc = find(AllUnits, { key: unit });
  if (unitDesc) {
    return unitDesc.toStandard(parseFloat(value.toString()));
  }
  return 0;
}

export function fromStandard(
  value: string | number,
  unit: (typeof AllUnits)[number]['key'],
) {
  const unitDesc = find(AllUnits, { key: unit });
  if (unitDesc) {
    return unitDesc.fromStandard(parseFloat(value.toString()));
  }
  return 0;
}

export function convertToUnit(
  value: string | number,
  currentUnit: (typeof AllUnits)[number]['key'],
  destUnit: (typeof AllUnits)[number]['key'],
) {
  const stdValue = toStandard(value, currentUnit);
  if (stdValue) {
    return fromStandard(stdValue, destUnit);
  }
  return 0;
}

export const MassUnits = MassUnitTypes.map((unit) => unit.key);
export const VolumeUnits = VolumeUnitTypes.map((unit) => unit.key);

export function generateUnitOptions(unit: string) {
  if (MassUnits.includes(unit)) {
    return MassUnitTypes;
  }
  if (VolumeUnits.includes(unit)) {
    return VolumeUnitTypes;
  }
  return [];
}
