// import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import cloneDeep from 'lodash.clonedeep';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import ClearIcon from '@mui/icons-material/Clear';
import { FoodItem } from '../../types/Log';
import useFoodSearch from '../../store/useFoodSearch';
import EditItem from './EditItem';
import ItemStats from './ItemStats';

type SearchAddProps = {
  handleClose: () => void;
  item: FoodItem | undefined;
  addItem: (item: FoodItem) => void;
  updateItem: (item: FoodItem) => void;
  deleteItem: (() => void) | undefined;
};

type SearchResultRowProps = {
  item: FoodItem;
  onClick: () => void;
};

function SearchResultRow({ item, onClick }: SearchResultRowProps) {
  return (
    <Grid onClick={onClick} item xs={12}>
      <Card sx={{ p: 1, margin: 1 }}>
        <ItemStats item={item} />
      </Card>
    </Grid>
  );
}

export default function SearchAdd({
  handleClose,
  addItem,
  deleteItem,
  updateItem,
  item,
}: SearchAddProps) {
  const {
    searchTerm, setSearchTerm, startSearch, loading, searchResults, noResults, reset,
  } = useFoodSearch();
  const [selectedFood, setSelectedFood] = useState<FoodItem | undefined>();

  useEffect(() => {
    setSelectedFood(item);
  }, [item]);

  const handleAdd = () => {
    if (selectedFood) {
      if (item) {
        updateItem(selectedFood);
      } else {
        addItem(selectedFood);
      }
      handleClose();
    }
  };

  if (selectedFood === undefined) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h5" component="div">
            Search
          </Typography>
          <Grid container spacing={2} sx={{ p: 1 }}>
            <Grid item xs={12}>
              <TextField
                sx={{ minWidth: '300px' }}
                fullWidth
                label="Search"
                value={searchTerm || ''}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && searchTerm) startSearch();
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchResults.length !== 0 || noResults ? (
                        <IconButton onClick={() => reset} disabled={!searchTerm}>
                          <ClearIcon />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => startSearch()} disabled={!searchTerm}>
                          <SearchIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {loading ? (
                <Skeleton variant="circular" width={40} height={40} />
              ) : null}
              {!loading && searchResults.length !== 0
                ? searchResults.map((resultItem) => (
                  <SearchResultRow
                    item={resultItem}
                    key={resultItem.fdcId}
                    onClick={() => setSelectedFood(cloneDeep(resultItem))}
                  />
                ))
                : null}
              {!loading && searchResults.length === 0 ? (
                <Typography variant="body1">No Results</Typography>
              ) : null}
            </Grid>
          </Grid>
        </CardContent>
        <Divider sx={{ marginBottom: 2 }} />
        <CardActions sx={{ p: 2, paddingTop: 0 }}>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Button
              sx={{ marginRight: 1 }}
              size="small"
              color="error"
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
            {deleteItem !== undefined ? (
              <Button
                size="small"
                color="error"
                variant="contained"
                onClick={deleteItem}
              >
                Delete
              </Button>
            ) : null}
            <Box sx={{ flexGrow: 2 }} />
          </Box>
        </CardActions>
      </Card>
    );
  }
  return (
    <EditItem
      selectedFood={selectedFood}
      setSelectedFood={setSelectedFood}
      handleAdd={handleAdd}
      handleClose={handleClose}
      existingItem={item}
      deleteItem={deleteItem}
      handleBack={() => setSelectedFood(undefined)}
    />
  );
}
