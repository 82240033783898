import { MutableRefObject, useCallback, useLayoutEffect } from 'react';
import Quagga, {
  QuaggaJSCodeReader,
  QuaggaJSReaderConfig,
  QuaggaJSResultObject_CodeResult,
  QuaggaJSResultObject,
} from '@ericblade/quagga2';
import validate from '@ericblade/barcode-validator';

export type ResultType = {
  codeResult: {
    code: string
    format: string
  }
};

function getMedian(arr: number[]) {
  arr.sort((a, b) => a - b);
  const half = Math.floor(arr.length / 2);
  if (arr.length % 2 === 1) {
    return arr[half];
  }
  return (arr[half - 1] + arr[half]) / 2;
}

function getMedianOfCodeErrors(decodedCodes: QuaggaJSResultObject_CodeResult['decodedCodes']) {
  const errors = decodedCodes.filter((x) => x.error !== undefined).map((x) => x.error || 1);
  // Not sure what to put here added a 0
  const medianOfErrors = getMedian(errors);
  return medianOfErrors;
}

const defaultConstraints = {
  width: 1280,
  height: 720,
};

const defaultLocatorSettings = {
  patchSize: 'medium',
  halfSample: true,
};

const defaultDecoders: (QuaggaJSReaderConfig | QuaggaJSCodeReader)[] = ['ean_reader', 'upc_reader', 'upc_e_reader'];

type ScannerProps = {
  onDetected: (code: QuaggaJSResultObject | null) => void,
  scannerRef: MutableRefObject<null>,
  onScannerReady?: () => void,
  cameraId?: string
  facingMode?: string,
  constraints?: object,
  locator?: object,
  numOfWorkers?: number,
  decoders?: (QuaggaJSReaderConfig | QuaggaJSCodeReader)[],
  locate?: boolean,
};

export default function Scanner({
  onDetected,
  scannerRef,
  onScannerReady,
  cameraId,
  facingMode,
  constraints = defaultConstraints,
  locator = defaultLocatorSettings,
  numOfWorkers = navigator.hardwareConcurrency || 0,
  decoders = defaultDecoders,
  locate = true,
}: ScannerProps) {
  const errorCheck = useCallback((result: QuaggaJSResultObject) => {
    if (!onDetected) {
      return;
    }
    const err = getMedianOfCodeErrors(result.codeResult.decodedCodes);
    // console.log(err);
    // if Quagga is at least 75% certain that it read correctly, then accept the code.
    if (err < 0.09 && result.codeResult.code) {
      // onDetected(result.codeResult.code);
      if (validate(result.codeResult.code)) {
        onDetected(result);
      }
    }
  }, [onDetected]);

  const drawOverlay = () => {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;
    // Clear last draws
    const width10 = parseInt(drawingCanvas.getAttribute('width') || '0', 10) * 0.2;
    const height10 = parseInt(drawingCanvas.getAttribute('height') || '0', 10) * 0.2;
    drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute('width') || '0', 10), parseInt(drawingCanvas.getAttribute('height') || '0', 10));
    drawingCtx.fillStyle = '#BBBBBB';
    drawingCtx.globalAlpha = 0.7;
    drawingCtx.fillRect(0, 0, 1280, 720);
    drawingCtx.globalAlpha = 1;
    drawingCtx.clearRect(width10, height10, parseInt(drawingCanvas.getAttribute('width') || '0', 10) - (2 * width10), parseInt(drawingCanvas.getAttribute('height') || '0', 10) - (2 * height10));
    drawingCtx.fillStyle = 'blue';
    drawingCtx.fillRect(width10, height10, parseInt(drawingCanvas.getAttribute('width') || '0', 10) - (2 * width10), parseInt(drawingCanvas.getAttribute('height') || '0', 10) - (2 * height10));
    drawingCtx.clearRect(width10 + 4, height10 + 4, parseInt(drawingCanvas.getAttribute('width') || '0', 10) - (2 * width10) - 8, parseInt(drawingCanvas.getAttribute('height') || '0', 10) - (2 * height10) - 8);
    drawingCtx.fillStyle = 'red';
    drawingCtx.fillRect(width10, (parseInt(drawingCanvas.getAttribute('height') || '0', 10) / 2) - 4, parseInt(drawingCanvas.getAttribute('width') || '0', 10) - (2 * width10), 8);
  };

  const handleProcessed = (result: QuaggaJSResultObject | undefined) => {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    // const drawingCanvas = Quagga.canvas.dom.overlay;
    // drawingCtx.font = '24px Arial';
    // drawingCtx.fillStyle = 'green';
    drawOverlay();
    // Clear last draws
    // drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute('width') || '0', 10),
    // parseInt(drawingCanvas.getAttribute('height') || '0', 10));
    // drawingCtx.fillStyle = '#55555555';
    // drawingCtx.fillRect(0, 0, 1280, 720);

    if (result) {
      if (result.box) {
        Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: 'blue', lineWidth: 2 });
      }
    }
  };

  useLayoutEffect(() => {
    Quagga.init({
      debug: false,
      inputStream: {
        type: 'LiveStream',
        constraints: {
          ...constraints,
          ...(cameraId && { deviceId: cameraId }),
          ...(!cameraId && { facingMode }),
        },
        target: scannerRef.current === null ? undefined : scannerRef.current,
      },
      locator,
      numOfWorkers,
      decoder: { readers: decoders, multiple: false },
      locate,
    }, (err) => {
      Quagga.onProcessed(handleProcessed);

      if (err) {
        // console.log('Error starting Quagga:', err);
        return;
      }
      if (scannerRef && scannerRef.current) {
        Quagga.start();
        if (onScannerReady) {
          drawOverlay();
          onScannerReady();
        }
      }
    });
    Quagga.onDetected(errorCheck);
    // drawOverlay();
    return () => {
      Quagga.offDetected(errorCheck);
      Quagga.offProcessed(handleProcessed);
      Quagga.stop();
    };
  }, [
    cameraId,
    onDetected,
    onScannerReady,
    scannerRef,
    errorCheck,
    constraints,
    locator,
    decoders,
    locate,
  ]);
  return null;
}
