import { useState } from 'react';
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DayCard from './DayCard';
import MealCard from './MealCard';
import MacroHeader from '../components/MacroHeader';
import useUserProfile from '../store/useUserProfile';
import useLog from '../store/useLog';
import AddMealMain from './addMeal/AddMealMain';
import { FoodItem } from '../types/Log';

function Log() {
  const [selectedDay, setSelectedDay] = useState(Date());
  const [addMeal, setAddMeal] = useState<string | undefined>(undefined);
  const [itemIndex, setItemIndex] = useState<number | undefined>(undefined);
  const { data: userProfile } = useUserProfile();

  const {
    data: log,
    addItem,
    deleteItem,
    updateItem,
    updateLog,
  } = useLog(dayjs(selectedDay).format('YYYYMMDD'));

  const handleAddFood = (meal: string) => {
    setAddMeal(meal);
  };

  const getFoodItem = (mealName?: string, index?: number) => {
    let foundItem: FoodItem | undefined;
    if (mealName && index !== undefined && log) {
      log.meals.forEach((meal) => {
        if (meal.name === mealName) {
          if (meal.items.length > index) {
            foundItem = meal.items[index];
          }
        }
      });
    }
    return foundItem;
  };

  const isOldMacros = () => {
    if (log && userProfile) {
      if (
        log.target_calories === userProfile.target_calories
        && log.target_carbohydrates === userProfile.target_carbohydrates
        && log.target_fat === userProfile.target_fat
        && log.target_protein === userProfile.target_protein
      ) {
        return false;
      }
      return true;
    }
    return false;
  };

  const updateMacros = () => {
    if (log && userProfile) {
      updateLog({
        ...log,
        target_calories: userProfile.target_calories,
        target_carbohydrates: userProfile.target_carbohydrates,
        target_fat: userProfile.target_fat,
        target_protein: userProfile.target_protein,
      });
    }
  };

  const closeModal = () => {
    setAddMeal(undefined);
    setItemIndex(undefined);
  };

  return (
    <>
      <AddMealMain
        open={addMeal !== undefined}
        item={getFoodItem(addMeal, itemIndex)}
        handleClose={closeModal}
        deleteItem={
          addMeal !== undefined && itemIndex !== undefined
            ? () => {
              deleteItem(addMeal, itemIndex);
              closeModal();
            }
            : undefined
        }
        addItem={(item: FoodItem) => {
          if (addMeal) addItem(addMeal, item);
        }}
        updateItem={(item: FoodItem) => {
          if (addMeal && itemIndex !== undefined) updateItem(addMeal, itemIndex, item);
        }}
      />
      <DayCard selectedDay={selectedDay} setSelectedDay={setSelectedDay} />
      <MacroHeader log={log} />
      {isOldMacros() ? (
        <Button onClick={updateMacros} sx={{ float: 'right' }}>
          <RestartAltIcon />
          Reset Macros
        </Button>
      ) : null}
      <br />
      {log?.meals.map((meal) => (
        <MealCard
          meal={meal}
          key={meal.name}
          handleAddFood={handleAddFood}
          editItem={(index: number) => {
            setItemIndex(index);
            handleAddFood(meal.name);
          }}
        />
      ))}
    </>
  );
}

export default Log;
