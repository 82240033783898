// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getAuth,
  signInWithEmailAndPassword,
  browserLocalPersistence,
} from 'firebase/auth';
import {
  getFirestore,
  CollectionReference,
  collection,
  DocumentData,
} from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCU9e81MGTVtM8G1RtAq09_uR7yW7wjzv0',
  authDomain: 'realsimplemacros.firebaseapp.com',
  projectId: 'realsimplemacros',
  storageBucket: 'realsimplemacros.appspot.com',
  messagingSenderId: '169293542709',
  appId: '1:169293542709:web:26db34ee37abf9b90b487c',
  measurementId: 'G-J7DQQFV0TN',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const firestore = getFirestore(app);
// auth.setPersistence('LOCAL');
export const analytics = getAnalytics(app);

// eslint-disable-next-line max-len
export const createCollection = <T = DocumentData>(collectionName: string) => collection(firestore, collectionName) as CollectionReference<T>;

export default app;

export const login = (email: string, password: string, setError: (error: string) => void) => {
  auth.setPersistence(browserLocalPersistence);
  signInWithEmailAndPassword(auth, email, password).catch(() => setError('Incorrect email or password, please try again.'));
};

export const logout = () => {
  auth.signOut();
};
