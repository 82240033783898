import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

type FoodRowProps = {
  topline: string;
  caption?: string;
  calories: number;
  protein: number;
  carb: number;
  fat: number;
  total?: boolean;
  handleEdit?: () => void;
};

function FoodRow({
  topline,
  caption,
  calories,
  protein,
  carb,
  fat,
  total,
  handleEdit,
}: FoodRowProps) {
  return (
    <Box
      component="tr"
      onClick={handleEdit}
      sx={{
        '&:hover': {
          backgroundColor: (theme) => (total ? undefined : theme.palette.background.default),
          cursor: () => (total ? undefined : 'pointer'),
        },
      }}
    >
      <td>
        <div>
          <Typography align={total ? 'right' : 'inherit'} variant="body2">
            {total ? <strong>{topline}</strong> : topline}
          </Typography>
          <Typography variant="caption">{caption}</Typography>
        </div>
      </td>
      <td>
        <Typography variant="caption">
          {total ? <strong>{calories}</strong> : calories}
        </Typography>
      </td>
      <td>
        <Typography variant="caption">
          {total ? (
            <strong>
              {protein}
              g
            </strong>
          ) : `${protein}g`}
        </Typography>
      </td>
      <td>
        <Typography variant="caption">
          {total ? (
            <strong>
              {carb}
              g
            </strong>
          ) : `${carb}g`}
        </Typography>
      </td>
      <td>
        <Typography variant="caption">
          {total ? (
            <strong>
              {fat}
              g
            </strong>
          ) : `${fat}g`}
        </Typography>
      </td>
    </Box>
  );
}

export default FoodRow;
