import Grid from '@mui/material/Grid';
import DateHeader from '../components/DateHeader';

type DayCardProps = {
  selectedDay: string;
  setSelectedDay: (day: string) => void;
};

function DayCard({ selectedDay, setSelectedDay }: DayCardProps) {
  return (
    <Grid container spacing={0} sx={{ paddingTop: '8px' }}>
      <Grid item xs={12}>
        <DateHeader selectedDay={selectedDay} setSelectedDay={setSelectedDay} />
      </Grid>
    </Grid>
  );
}

export default DayCard;
