import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FoodItem } from '../../types/Log';

type ItemStatsProps = {
  item: FoodItem
};

export default function ItemStats({ item }: ItemStatsProps) {
  return (
    <>
      <Box
        sx={{
          paddingLeft: 1,
          paddingRight: 1,
          marginTop: -1,
          marginRight: -1,
          color: (theme) => theme.palette.getContrastText(theme.palette.primary.light),
          backgroundColor: (theme) => theme.palette.primary.light,
          width: 'fit-content',
          float: 'right',
        }}
      >
        <Typography variant="overline">{`${item.source} ${item.usda_type}`}</Typography>
      </Box>
      <Typography variant="overline">
        {item.brandName || 'Generic'}
      </Typography>
      <Typography variant="subtitle1">{item.name}</Typography>
      <Divider sx={{ marginBottom: 1 }} />
      <Grid container>
        <Grid item xs={4}>
          <Typography variant="body2">
            {`${item.serving_calories} Calories`}
          </Typography>
          <Typography variant="body2">
            {`${item.serving_protein}g Protein`}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2">
            {`${item.serving_carbohydrates}g Carbs`}
          </Typography>
          <Typography variant="body2">
            {`${item.serving_fat}g Fat`}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2">Serving Size:</Typography>
          <Typography variant="body2">
            {item.serving_size + item.serving_size_unit}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
