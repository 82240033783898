import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import cloneDeep from 'lodash.clonedeep';
import { FoodItem } from '../../types/Log';
import BarcodeScanner from './BarcodeScanner';
import useFoodSearch from '../../store/useFoodSearch';
import EditItem from './EditItem';

type QuickAddProps = {
  handleClose: () => void;
  item: FoodItem | undefined;
  addItem: (item: FoodItem) => void;
  updateItem: (item: FoodItem) => void;
  deleteItem: (() => void) | undefined;
};

export default function QuickAdd({
  handleClose,
  addItem,
  deleteItem,
  updateItem,
  item,
}: QuickAddProps) {
  const [selectedFood, setSelectedFood] = useState<FoodItem | undefined>(undefined);
  const {
    setSearchTerm,
    startSearch,
    loading,
    noResults,
    upcResult,
    reset,
    searchTerm,
  } = useFoodSearch();

  useEffect(() => {
    if (upcResult) {
      const currDate = new Date();
      setSelectedFood({ ...cloneDeep(upcResult), time: `${currDate.getHours()}:${currDate.getMinutes()}` });
    }
  }, [upcResult]);

  useEffect(() => {
    if (item) {
      setSelectedFood(item);
    }
  }, [item]);

  const handleAdd = () => {
    /*
    const currDate = new Date();
    const newItem: FoodItem = {
      name,
      time: `${currDate.getHours()}:${currDate.getMinutes()}`,
      type: 'quickadd',
      amount: 1,
      amount_unit: 'each',
      serving_size: 1,
      serving_size_unit: 'each',
      serving_calories: 0,
      serving_carbohydrates: parseInt(carbohydrates, 10),
      serving_protein: parseInt(protein, 10),
      serving_fat: parseInt(fat, 10),
    };
    newItem.serving_calories = newItem.serving_carbohydrates * 4
      + newItem.serving_fat * 9
      + newItem.serving_protein * 4;
    */
    if (selectedFood) {
      if (item) {
        updateItem(selectedFood);
      } else {
        addItem(selectedFood);
      }
    }
    handleClose();
  };

  const handleUPC = (upc: string) => {
    let newUPC = upc;
    if (upc.length > 12) {
      newUPC = upc.substring(upc.length - 12);
    }
    setSearchTerm(newUPC);
    startSearch(newUPC);
  };

  if (selectedFood) {
    return (
      <EditItem
        selectedFood={selectedFood}
        setSelectedFood={setSelectedFood}
        handleAdd={handleAdd}
        handleClose={handleClose}
        deleteItem={deleteItem}
      />
    );
  }

  return (
    <Card sx={{ minWidth: '400px' }}>
      <CardContent>
        <Typography variant="h5" component="div">
          Scan a Barcode
        </Typography>
        {(selectedFood === undefined && !loading && !noResults) ? (
          <BarcodeScanner handleUPC={handleUPC} />
        ) : null}
        {loading && (
          <Typography>Loading</Typography>
        )}
        {noResults && (
          <>
            <Typography>No foods found for that Barcode.</Typography>
            <Typography>{searchTerm}</Typography>
            <Button onClick={() => reset()}>Try Again</Button>
          </>
        )}
      </CardContent>
      <Divider sx={{ marginBottom: 2 }} />
      <CardActions sx={{ p: 2, paddingTop: 0 }}>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Button
            sx={{ marginRight: 1 }}
            size="small"
            color="error"
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>
          {deleteItem !== undefined ? (
            <Button
              size="small"
              color="error"
              variant="contained"
              onClick={deleteItem}
            >
              Delete
            </Button>
          ) : null}
          <Box sx={{ flexGrow: 2 }} />
        </Box>
      </CardActions>
    </Card>
  );
}
