import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { Divider, MenuItem, Select } from '@mui/material';
import { convertToUnit, generateUnitOptions } from '../../util/units';
import { calculateCalMacros } from '../../store/useLog';
import { FoodItem } from '../../types/Log';
import ItemStats from './ItemStats';

type EditItemProps = {
  selectedFood: FoodItem
  setSelectedFood: React.Dispatch<React.SetStateAction<FoodItem | undefined>>
  handleAdd: () => void
  existingItem?: FoodItem
  handleClose: () => void
  deleteItem: (() => void) | undefined
  handleBack?: (() => void)
};

export default function EditItem(
  {
    selectedFood,
    setSelectedFood,
    handleAdd,
    existingItem,
    handleClose,
    deleteItem,
    handleBack,
  }: EditItemProps,
) {
  const [amountText, setAmountText] = useState('');

  useEffect(() => {
    setAmountText(selectedFood.amount.toString());
  }, [selectedFood]);

  const handleAmountChange = (newAmount: string) => {
    setAmountText(newAmount);
    if (!newAmount.endsWith('.') && newAmount !== null && newAmount !== '') {
      setSelectedFood((oldVal) => {
        if (oldVal) {
          return { ...oldVal, amount: parseFloat(newAmount) };
        }
        return oldVal;
      });
    }
  };

  const handleAmountUnitChange = (newAmountUnit: string) => {
    setSelectedFood((oldVal) => {
      if (oldVal) {
        if (oldVal.amount_unit === newAmountUnit) {
          return oldVal;
        }
        let newAmount = oldVal.amount;
        if (oldVal.amount_unit === 'each') {
          newAmount = convertToUnit(
            oldVal.amount * oldVal.serving_size,
            oldVal.serving_size_unit,
            newAmountUnit,
          );
        } else if (newAmountUnit === 'each') {
          newAmount = convertToUnit(
            oldVal.amount,
            oldVal.amount_unit,
            oldVal.serving_size_unit,
          ) / oldVal.serving_size;
        } else {
          newAmount = convertToUnit(oldVal.amount, oldVal.amount_unit, newAmountUnit);
        }
        return { ...oldVal, amount: newAmount, amount_unit: newAmountUnit };
      }
      return oldVal;
    });
  };

  const {
    calories,
    carbohydrates,
    protein,
    fat,
  } = calculateCalMacros(selectedFood);
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          {handleBack ? (
            <IconButton onClick={() => setSelectedFood(undefined)}>
              <ArrowBackIcon />
            </IconButton>
          ) : null}
          Search
        </Typography>
        <Grid container spacing={2} sx={{ p: 1 }}>
          <Grid item xs={12}>
            <ItemStats item={selectedFood} />
            <Divider sx={{ marginY: 2 }} />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Amount"
              fullWidth
              value={amountText}
              onChange={(e) => handleAmountChange(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              fullWidth
              value={selectedFood.amount_unit}
              onChange={(e) => handleAmountUnitChange(e.target.value)}
            >
              <MenuItem value="each">
                Serving
                (
                {selectedFood.serving_size + selectedFood.serving_size_unit}
                )
              </MenuItem>
              {generateUnitOptions(selectedFood.serving_size_unit).map(
                (unit) => (
                  <MenuItem key={unit.key} value={unit.key}>{unit.label}</MenuItem>
                ),
              )}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Calculated</strong>
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={3}><Typography variant="body2">{`Calories: ${calories}`}</Typography></Grid>
          <Grid item xs={3}><Typography variant="body2">{`Carbs: ${carbohydrates}g`}</Typography></Grid>
          <Grid item xs={3}><Typography variant="body2">{`Protein: ${protein}g`}</Typography></Grid>
          <Grid item xs={3}><Typography variant="body2">{`Fat: ${fat}g`}</Typography></Grid>
        </Grid>
      </CardContent>
      <Divider sx={{ marginBottom: 2 }} />
      <CardActions sx={{ p: 2, paddingTop: 0 }}>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Button
            sx={{ marginRight: 1 }}
            size="small"
            color="error"
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>
          {deleteItem !== undefined ? (
            <Button
              size="small"
              color="error"
              variant="contained"
              onClick={deleteItem}
            >
              Delete
            </Button>
          ) : null}
          <Box sx={{ flexGrow: 2 }} />
          <Button
            sx={{ float: 'right' }}
            size="small"
            variant="contained"
            onClick={handleAdd}
            disabled={(amountText.endsWith('.') || amountText === null || amountText === '')}
          >
            {existingItem === undefined ? 'Add' : 'Update'}
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
}
