import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LogIcon from '@mui/icons-material/Assignment';
import RecipesIcon from '@mui/icons-material/List';
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import Paper from '@mui/material/Paper';
import { useNavigate, useLocation } from 'react-router-dom';

const urls = ['/log', '/recipes', '/calendar', '/account'];

export default function SimpleBottomNavigation() {
  const [value, setValue] = useState(-1);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    for (let index = 0; index < urls.length; index += 1) {
      if (location.pathname.startsWith(urls[index])) {
        setValue(index);
      }
    }
  }, [location]);

  return (
    <Box>
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 3,
        }}
        elevation={10}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            navigate(urls[newValue]);
          }}
        >
          <BottomNavigationAction label="Log" icon={<LogIcon />} />
          <BottomNavigationAction label="Recipes" icon={<RecipesIcon />} />
          <BottomNavigationAction label="Calendar" icon={<CalendarIcon />} />
          <BottomNavigationAction label="Account" icon={<PersonIcon />} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
