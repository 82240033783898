import {
  Card,
  Grid,
  CardContent,
  Typography,
  CardActions,
  Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircle';
import FoodRow from './FoodRow';
import { FoodItem, MealLog } from '../types/Log';
import titleCase from '../store/titleCase';
import { calculateCalMacros } from '../store/useLog';

type MealCardProps = {
  meal: MealLog;
  handleAddFood: (meal: string) => void;
  editItem: (index: number) => void;
};

function MealCard({ meal, handleAddFood, editItem }: MealCardProps) {
  const generateCaption = (item: FoodItem) => {
    if (item.type === 'quickadd') {
      return 'Quick Add';
    }
    if (item.type === 'recipe') {
      return `Recipe - ${(item.amount * item.serving_size).toString()}${item.amount_unit}`;
    }
    return `${titleCase(item.brandName || '')} ${item.amount}${item.amount_unit}`;
  };

  return (
    <Grid container spacing={2} sx={{ paddingTop: '8px' }}>
      <Grid item xs={12}>
        <Card>
          <CardContent sx={{ paddingTop: '8px', paddingBottom: 0 }}>
            <Typography variant="h6">{meal.name}</Typography>
            {meal.items.length === 0 ? (
              <Typography sx={{ textAlign: 'center' }} variant="body1">
                Nothing Logged
              </Typography>
            ) : (
              <table style={{ width: '100%', borderSpacing: 0 }}>
                <tbody>
                  <tr>
                    <th>&nbsp;</th>
                    <th>
                      <small>Cal</small>
                    </th>
                    <th>
                      <small>P</small>
                    </th>
                    <th>
                      <small>C</small>
                    </th>
                    <th>
                      <small>F</small>
                    </th>
                  </tr>
                  {meal.items.map((item, index) => {
                    const {
                      calories,
                      carbohydrates,
                      protein,
                      fat,
                    } = calculateCalMacros(item);
                    return (
                      <FoodRow
                        handleEdit={() => editItem(index)}
                        key={item.name + item.time}
                        topline={item.name}
                        caption={generateCaption(item)}
                        calories={calories}
                        protein={protein}
                        carb={carbohydrates}
                        fat={fat}
                      />
                    );
                  })}
                  {meal.items.length !== 0 ? (
                    <FoodRow
                      total
                      topline="Total"
                      calories={meal.meal_calories}
                      protein={meal.meal_protein}
                      carb={meal.meal_carbohydrates}
                      fat={meal.meal_fat}
                    />
                  ) : null}
                </tbody>
              </table>
            )}
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button variant="outlined" onClick={() => handleAddFood(meal.name)}>
              <AddIcon />
              Add
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}

export default MealCard;
