import { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import AssignmentIcon from '@mui/icons-material/Assignment';
import QuickAdd from './QuickAdd';
import { FoodItem } from '../../types/Log';
import SearchAdd from './SearchAdd';
import ScanAdd from './ScanAdd';

type AddMealMainProps = {
  open: boolean;
  handleClose: () => void;
  addItem: (item: FoodItem) => void;
  updateItem: (item: FoodItem) => void;
  deleteItem: (() => void) | undefined;
  item: FoodItem | undefined;
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 660,
  maxHeight: '100vh',
  overflow: 'scroll',
  // bgcolor: 'background.paper',
  // border: '2px solid #000',
  // boxShadow: 24,
  // p: 4,
};

const buttonStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export default function AddMealMain({
  open,
  handleClose,
  addItem,
  item,
  deleteItem,
  updateItem,
}: AddMealMainProps) {
  const [stage, setStage] = useState<undefined | 'scan' | 'quickadd' | 'recipe' | 'search'>(undefined);

  const close = () => {
    setStage(undefined);
    handleClose();
  };

  useEffect(() => {
    if (item) {
      setStage(item.type);
    }
  }, [item]);

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {stage === undefined ? (
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                Select a method
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="large"
                variant="contained"
                onClick={() => setStage('quickadd')}
              >
                <Box sx={buttonStyle}>
                  <AddIcon />
                  Quick Add
                </Box>
              </Button>
              <Button
                size="large"
                variant="contained"
                onClick={() => setStage('search')}
              >
                <Box sx={buttonStyle}>
                  <SearchIcon />
                  Search
                </Box>
              </Button>
              <Button
                size="large"
                variant="contained"
                onClick={() => setStage('scan')}
              >
                <Box sx={buttonStyle}>
                  <PhotoCameraIcon />
                  Scan
                </Box>
              </Button>
              <Button
                size="large"
                variant="contained"
                onClick={() => setStage('recipe')}
              >
                <Box sx={buttonStyle}>
                  <AssignmentIcon />
                  Recipe
                </Box>
              </Button>
            </CardActions>
          </Card>
        ) : null}
        {stage === 'quickadd' ? (
          <QuickAdd
            handleClose={close}
            addItem={addItem}
            updateItem={updateItem}
            deleteItem={deleteItem}
            item={item}
          />
        ) : null}
        {stage === 'search' ? (
          <SearchAdd
            handleClose={close}
            addItem={addItem}
            updateItem={updateItem}
            deleteItem={deleteItem}
            item={item}
          />
        ) : null}
        {stage === 'scan' ? (
          <ScanAdd
            addItem={addItem}
            updateItem={updateItem}
            handleClose={close}
            deleteItem={deleteItem}
            item={item}
          />
        ) : null}
      </Box>
    </Modal>
  );
}
