import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { login } from '../firebase';
import Logo from '../svg/LogoComponent';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | undefined>(undefined);

  return (
    <Container
      maxWidth="xs"
      sx={{
        paddingLeft: '5px',
        paddingRight: '5px',
        paddingBottom: '60px',
        paddingTop: 2,
      }}
    >
      <Paper sx={{ marginTop: 4 }}>
        <Logo />
        <Grid container sx={{ p: 2, paddingTop: 0 }} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" textAlign="center">
              Sign In
            </Typography>
          </Grid>
          {error ? (
            <Grid item xs={12}>
              <Typography
                sx={{ color: (theme) => theme.palette.error.main }}
                variant="overline"
              >
                {error}
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TextField
              label="Email"
              value={email}
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Password"
              type="password"
              value={password}
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') login(email, password, setError);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button sx={{ float: 'right' }} variant="contained" onClick={() => login(email, password, setError)}>
              Log In
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
