/* eslint-disable react/no-array-index-key */
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import cloneDeep from 'lodash.clonedeep';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import useUserProfile from '../store/useUserProfile';
import MacrosPercentageSetup from './MacrosPercentage';
import { logout } from '../firebase';

type MealRowProps = {
  mealName: string,
  index: number,
  totalMeals: number,
  moveMealUp: (index: number) => void
  moveMealDown: (index: number) => void
  removeMeal: (index: number) => void
  updateMealName: (name: string, index: number) => void
};

function MealRow({
  mealName,
  index,
  moveMealUp,
  moveMealDown,
  removeMeal,
  totalMeals,
  updateMealName,
}: MealRowProps) {
  return (
    <Box sx={{ display: 'flex', padding: 1, alignItems: 'center' }}>
      <Typography sx={{ paddingRight: 0.25 }} variant="h5">{index + 1}</Typography>
      <IconButton disabled={index === 0} size="small" onClick={() => moveMealUp(index)}>
        <KeyboardArrowUpIcon />
      </IconButton>
      <IconButton size="small" disabled={(index + 1) === totalMeals} onClick={() => moveMealDown(index)}>
        <KeyboardArrowDownIcon />
      </IconButton>
      <TextField fullWidth size="small" value={mealName} onChange={(e) => updateMealName(e.target.value, index)} />
      <IconButton size="small" onClick={() => removeMeal(index)}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
}

export default function Account() {
  const { data, updateData, save } = useUserProfile();
  const navigate = useNavigate();

  const moveMealUp = (index: number) => {
    if (index !== 0 && data) {
      const newData = cloneDeep(data);
      const element = newData.default_meals.splice(index, 1)[0];
      newData.default_meals.splice(index - 1, 0, element);
      updateData(newData);
    }
  };

  const moveMealDown = (index: number) => {
    if (data && index < data.default_meals.length) {
      const newData = cloneDeep(data);
      const element = newData.default_meals.splice(index, 1)[0];
      newData.default_meals.splice(index + 1, 0, element);
      updateData(newData);
    }
  };

  const removeMeal = (index: number) => {
    if (data) {
      const newData = cloneDeep(data);
      newData.default_meals.splice(index, 1);
      updateData(newData);
    }
  };

  const addMeal = () => {
    if (data) {
      const newData = cloneDeep(data);
      newData.default_meals.push('New Meal');
      updateData(newData);
    }
  };

  const updateMealName = (name: string, index: number) => {
    if (data) {
      const newData = cloneDeep(data);
      newData.default_meals[index] = name;
      updateData(newData);
    }
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={12}>
          <Typography sx={{ paddingTop: 2, display: 'inline-block' }} variant="h4">Account Settings</Typography>
          <Button variant="outlined" color="error" onClick={logout} sx={{ float: 'right', marginTop: 2 }}>Log Out</Button>
        </Grid>
      </Grid>
      {
        data === undefined ? (
          <Typography variant="body1">Loading...</Typography>
        ) : (
          <Paper>
            <Grid container spacing={2} sx={{ padding: 2 }}>
              <Grid item xs={6}>
                <TextField label="First Name" fullWidth value={data?.first_name || ''} onChange={(e) => updateData({ ...data, first_name: e.target.value })} />
              </Grid>
              <Grid item xs={6}>
                <TextField label="Last Name" fullWidth value={data?.last_name || ''} onChange={(e) => updateData({ ...data, last_name: e.target.value })} />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex' }}>
                  <Typography sx={{ flexGrow: 2 }} variant="h6">Default Meals</Typography>
                  <Box sx={{ float: 'right' }}>
                    <Button variant="outlined" size="small" onClick={addMeal}>
                      <AddIcon />
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {data?.default_meals.map((meal, index) => (
                  <MealRow
                    key={index}
                    mealName={meal}
                    index={index}
                    moveMealDown={moveMealDown}
                    moveMealUp={moveMealUp}
                    removeMeal={removeMeal}
                    totalMeals={data.default_meals.length}
                    updateMealName={updateMealName}
                  />
                ))}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ paddingTop: 2 }} variant="h6">Nutrition Setup</Typography>
              </Grid>
              <Grid item xs={12}>
                <MacrosPercentageSetup data={data} updateData={updateData} />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', width: '100%' }}>
                  <Button variant="outlined" onClick={() => navigate('/')}>Cancel</Button>
                  <Box sx={{ flexGrow: 2 }} />
                  <Button variant="contained" onClick={save}>Save</Button>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        )
      }
    </>
  );
}
