import { createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

/*
 Black: #11111F
 White: #FFFFFF
 Blue: #41B6E6
*/

const CustomTheme = createTheme({
  palette: {
    primary: {
      main: '#41B6E6', // green[400],
    },
    background: {
      default: grey[100],
    },
  },
});

export default CustomTheme;
