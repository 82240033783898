import { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Container } from '@mui/material';
import { onAuthStateChanged, User } from 'firebase/auth';
import BottomNav from './nav/BottomNav';
import Log from './log/Log';
import Calendar from './calendar/Calendar';
import Recipes from './recipes/Recipes';
import Account from './account/Account';
import { auth } from './firebase';
import Login from './login/Login';

function App() {
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    onAuthStateChanged(auth, (u) => {
      setUser(u);
      if (initializing) setInitializing(false);
    });
  });

  if (initializing) {
    return <h1> Loading ...</h1>;
  }

  if (user !== null) {
    return (
      <div className="App">
        <BottomNav />
        <Container
          maxWidth="sm"
          sx={{
            paddingLeft: '5px',
            paddingRight: '5px',
            paddingBottom: '60px',
          }}
        >
          <Routes>
            <Route path="/" element={<Navigate to="/log" replace />} />
            <Route path="login" element={<Navigate to="/" replace />} />
            <Route path="log" element={<Log />} />
            <Route path="calendar" element={<Calendar />} />
            <Route path="recipes" element={<Recipes />} />
            <Route path="account" element={<Account />} />
          </Routes>
        </Container>
      </div>
    );
  }

  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
}

export default App;
