export default function Log() {
  return (
    <div>
      <svg viewBox="0 225.77 2759.41 1906.502">
        <g fill="#41B6E6" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: 'normal' }}>
          <g>
            <g>
              <path d="M614.536 1452.568h-32.707v74.093h-39.359V1326.378h81.905c10.033 0 19.201 1.305 27.493 3.908 8.298 2.608 15.392 6.513 21.273 11.72 5.891 5.213 10.426 11.579 13.606 19.106 3.186 7.525 4.776 16.205 4.776 26.049 0 8.488 -1.013 16.015 -3.04 22.576 -2.028 6.557 -4.776 12.247 -8.248 17.073a47.26 47.26 0 0 1 -12.156 11.865 63.447 63.447 0 0 1 -14.47 7.235l45.44 80.751h-46.307zm-32.707 -37.046h43.704c8.68 0 15.678 -1.977 20.981 -5.935 5.308 -3.953 7.963 -10.56 7.963 -19.823 0 -8.881 -2.655 -15.243 -7.963 -19.106 -5.302 -3.858 -12.297 -5.785 -20.981 -5.785h-43.699z" fill="#000000" />
              <path d="M722.78 1326.378h116.06v39.364h-76.696v39.074h47.461v39.359h-47.461v43.704h78.718v38.782h-118.082z" fill="#000000" />
              <path d="M925.959 1326.378h32.124l71.781 200.283h-43.127l-12.152 -36.464h-64.836l-11.865 36.464h-43.414zm-5.498 126.481h43.122L941.879 1386.868z" fill="#000000" />
              <path d="M1053.016 1326.378h39.359v161.502h88.276v38.782h-127.635z" fill="#000000" />
              <path d="M1361.252 1324.35c12.739 0 24.459 2.173 35.17 6.513 10.707 4.345 19.246 10.184 25.613 17.51l-13.606 12.156a51.526 51.526 0 0 0 -20.258 -13.748c-7.907 -2.99 -16.496 -4.485 -25.758 -4.485 -5.79 0 -11.48 0.577 -17.073 1.735 -5.598 1.154 -10.516 2.895 -14.766 5.213 -4.245 2.312 -7.667 5.302 -10.27 8.97 -2.608 3.662 -3.914 8.103 -3.914 13.316 0 5.785 1.646 10.707 4.923 14.76 3.281 4.049 7.525 7.62 12.739 10.707 5.208 3.085 11.137 5.79 17.801 8.103a1622.675 1622.675 0 0 0 20.404 6.948c6.945 2.312 13.647 4.725 20.113 7.235 6.462 2.508 12.202 5.403 17.218 8.68 7.525 5.018 13.697 11.098 18.523 18.237 4.826 7.14 7.235 16.496 7.235 28.076 0 10.415 -2.217 19.151 -6.652 26.19a51.15 51.15 0 0 1 -17.369 16.933c-7.14 4.245 -15.051 7.235 -23.731 8.97a131.096 131.096 0 0 1 -25.758 2.608 166.268 166.268 0 0 1 -21.709 -1.45 134.453 134.453 0 0 1 -21.128 -4.485 93.188 93.188 0 0 1 -19.1 -8.103 63.415 63.415 0 0 1 -15.634 -12.301l12.448 -13.025c7.14 7.336 16.451 12.834 27.931 16.496a115.85 115.85 0 0 0 35.456 5.498c18.136 0 32.174 -3.377 42.109 -10.13 9.935 -6.753 14.906 -15.919 14.906 -27.493 0 -6.948 -1.882 -12.975 -5.645 -18.091a58.374 58.374 0 0 0 -14.038 -13.455c-5.98 -4.245 -12.055 -7.475 -18.233 -9.698a261.692 261.692 0 0 0 -18.663 -5.93 271.697 271.697 0 0 1 -19.106 -6.081c-6.468 -2.312 -12.979 -5.69 -19.536 -10.13 -6.563 -4.435 -11.966 -9.743 -16.205 -15.915 -4.249 -6.176 -6.371 -13.897 -6.371 -23.154 0 -7.14 1.495 -13.651 4.485 -19.536a42.511 42.511 0 0 1 12.593 -14.906c5.403 -4.053 11.916 -7.19 19.536 -9.408 7.62 -2.217 16.06 -3.331 25.321 -3.331z" fill="#000000" />
              <path d="M1480.348 1326.378h18.237v200.283h-18.233z" fill="#000000" />
              <path d="M1737.22 1326.378v200.283h-18.237v-168.154l-71.775 168.154h-6.948l-71.775 -168.154v168.154h-18.237V1326.378h24.027l69.463 162.654 69.463 -162.654z" fill="#000000" />
              <path d="M1856.752 1326.378c9.452 0 18.041 1.205 25.758 3.618 7.717 2.413 14.329 6.081 19.828 10.998 5.498 4.923 9.788 11.003 12.878 18.237 3.085 7.235 4.63 15.578 4.63 25.036 0 9.648 -1.545 18.136 -4.63 25.466 -3.091 7.336 -7.38 13.461 -12.878 18.378 -5.498 4.923 -12.111 8.635 -19.828 11.143 -7.717 2.508 -16.306 3.763 -25.758 3.763h-54.41v83.646h-18.237V1326.378zm-54.41 98.987h54.702c6.367 0 12.297 -0.823 17.795 -2.458 5.498 -1.646 10.231 -4.103 14.183 -7.386 3.953 -3.281 7.043 -7.525 9.261 -12.733 2.217 -5.213 3.327 -11.288 3.327 -18.237 0 -6.945 -1.104 -12.975 -3.327 -18.088 -2.217 -5.118 -5.308 -9.261 -9.261 -12.443 -3.953 -3.186 -8.68 -5.548 -14.183 -7.094 -5.498 -1.545 -11.43 -2.312 -17.795 -2.312h-54.702z" fill="#000000" />
              <path d="M1957.324 1326.378h18.237v182.628h99.851v17.659h-118.088z" fill="#000000" />
              <path d="M2109.565 1326.378h105.059v18.523h-86.826v70.04h53.834v18.237h-53.834v75.828h89.144v17.659h-107.377z" fill="#000000" />
              <path d="M1011.263 1577.027V1777.31h-18.233v-168.158l-71.775 168.158h-6.948l-71.781 -168.158v168.158h-18.233V1577.027h24.021l69.463 162.654 69.463 -162.654z" />
              <path d="M1114.155 1577.027h17.659l71.775 200.283h-21.418l-14.183 -41.392h-90.879l-13.893 41.392h-20.836zm-31.543 141.534h79.878l-39.936 -116.643z" />
              <path d="M1317.423 1591.689c-9.979 0 -19.045 1.776 -27.203 5.323 -8.153 3.552 -15.107 8.92 -20.865 16.12 -5.755 7.194 -10.214 16.164 -13.381 26.913 -3.165 10.741 -4.751 23.215 -4.751 37.413s1.586 26.626 4.751 37.277c3.161 10.646 7.626 19.566 13.381 26.765 5.76 7.194 12.712 12.618 20.871 16.261 8.153 3.647 17.218 5.468 27.192 5.468 9.788 0 18.855 -1.726 27.203 -5.178 8.348 -3.457 15.593 -8.831 21.729 -16.12l13.817 10.074c-7.676 9.784 -16.838 17.078 -27.488 21.875 -10.646 4.796 -22.306 7.194 -34.969 7.194 -12.091 0 -23.314 -2.208 -33.675 -6.622a74.028 74.028 0 0 1 -26.913 -19.566c-7.576 -8.639 -13.526 -19.432 -17.846 -32.381 -4.314 -12.955 -6.472 -27.97 -6.472 -45.049 0 -17.073 2.158 -32.09 6.472 -45.043 4.314 -12.949 10.264 -23.841 17.846 -32.666 7.576 -8.831 16.552 -15.448 26.913 -19.858 10.361 -4.414 21.588 -6.622 33.675 -6.622 13.431 0 25.617 2.638 36.555 7.916s20.054 13.094 27.343 23.455l-13.526 10.365c-6.14 -8.062 -13.532 -13.962 -22.165 -17.706 -8.63 -3.739 -18.132 -5.61 -28.497 -5.61z" />
              <path d="M1496.924 1693.666h-50.653v83.646h-18.233V1577.029h72.648c9.452 0 18.037 1.205 25.758 3.618 7.717 2.407 14.323 6.03 19.823 10.856 5.498 4.822 9.793 10.853 12.878 18.088 3.091 7.235 4.63 15.583 4.63 25.036 0 8.488 -1.154 15.965 -3.472 22.426 -2.312 6.472 -5.595 12.111 -9.838 16.937a49.107 49.107 0 0 1 -15.051 11.575c-5.79 2.895 -12.253 4.917 -19.391 6.075l47.177 85.672h-22.286zm-50.653 -17.656h54.702c6.371 0 12.301 -0.817 17.801 -2.458 5.498 -1.64 10.225 -4.1 14.183 -7.38 3.953 -3.281 7.04 -7.525 9.261 -12.733 2.217 -5.213 3.327 -11.288 3.327 -18.237 0 -6.945 -1.11 -12.975 -3.327 -18.088 -2.223 -5.118 -5.308 -9.261 -9.261 -12.443 -3.958 -3.186 -8.68 -5.548 -14.183 -7.094 -5.498 -1.545 -11.433 -2.318 -17.801 -2.318h-54.702z" />
              <path d="M1677.671 1573.264c12.087 0 23.263 2.208 33.529 6.622 10.264 4.414 19.089 11.033 26.481 19.858 7.386 8.831 13.144 19.717 17.268 32.666 4.124 12.955 6.187 27.97 6.187 45.043 0 17.078 -2.057 32.094 -6.187 45.049 -4.124 12.949 -9.884 23.74 -17.268 32.375a71.745 71.745 0 0 1 -26.481 19.577c-10.264 4.414 -21.443 6.617 -33.529 6.617 -12.091 0 -23.269 -2.208 -33.535 -6.622a71.745 71.745 0 0 1 -26.481 -19.566c-7.386 -8.639 -13.144 -19.432 -17.268 -32.381 -4.124 -12.955 -6.187 -27.97 -6.187 -45.049 0 -17.073 2.057 -32.09 6.187 -45.043 4.124 -12.949 9.884 -23.841 17.268 -32.666 7.39 -8.831 16.215 -15.448 26.481 -19.858 10.264 -4.414 21.443 -6.622 33.535 -6.622zm0 18.423c-20.339 0 -36.268 7.099 -47.784 21.297 -11.51 14.198 -17.268 35.691 -17.268 64.469 0 28.396 5.855 49.794 17.561 64.188 11.705 14.39 27.534 21.584 47.491 21.584 19.953 0 35.782 -7.194 47.488 -21.584 11.705 -14.394 17.561 -35.788 17.561 -64.188 0 -28.778 -5.76 -50.271 -17.268 -64.469 -11.513 -14.198 -27.443 -21.297 -47.778 -21.297z" />
              <path d="M1861.021 1575.001c12.733 0 24.453 2.173 35.164 6.513 10.707 4.345 19.246 10.18 25.613 17.51l-13.601 12.156a51.526 51.526 0 0 0 -20.258 -13.748c-7.913 -2.99 -16.496 -4.485 -25.758 -4.485 -5.79 0 -11.484 0.577 -17.078 1.735 -5.595 1.154 -10.516 2.895 -14.76 5.208 -4.245 2.318 -7.671 5.308 -10.275 8.976 -2.608 3.662 -3.908 8.103 -3.908 13.31 0 5.79 1.64 10.711 4.917 14.766 3.286 4.049 7.525 7.62 12.744 10.707 5.202 3.085 11.137 5.79 17.795 8.103a1653.887 1653.887 0 0 0 20.404 6.948c6.945 2.312 13.647 4.725 20.119 7.235 6.462 2.508 12.202 5.403 17.218 8.68 7.525 5.018 13.697 11.098 18.523 18.237 4.822 7.14 7.235 16.496 7.235 28.076 0 10.415 -2.217 19.151 -6.658 26.19a51.15 51.15 0 0 1 -17.363 16.933c-7.14 4.245 -15.051 7.235 -23.731 8.97a131.096 131.096 0 0 1 -25.762 2.608 166.268 166.268 0 0 1 -21.703 -1.45 134.453 134.453 0 0 1 -21.132 -4.485 93.188 93.188 0 0 1 -19.1 -8.103 63.415 63.415 0 0 1 -15.628 -12.301l12.443 -13.025c7.14 7.33 16.451 12.834 27.931 16.496a115.85 115.85 0 0 0 35.456 5.498c18.136 0 32.174 -3.377 42.114 -10.13 9.935 -6.753 14.9 -15.919 14.9 -27.493 0 -6.948 -1.877 -12.975 -5.639 -18.091a58.374 58.374 0 0 0 -14.038 -13.455c-5.986 -4.245 -12.061 -7.475 -18.237 -9.698a261.692 261.692 0 0 0 -18.663 -5.93 271.697 271.697 0 0 1 -19.106 -6.081c-6.462 -2.312 -12.975 -5.69 -19.536 -10.13 -6.557 -4.435 -11.96 -9.743 -16.205 -15.919 -4.245 -6.172 -6.371 -13.893 -6.371 -23.154 0 -7.14 1.501 -13.647 4.491 -19.536a42.511 42.511 0 0 1 12.593 -14.9c5.399 -4.053 11.91 -7.19 19.532 -9.408 7.626 -2.223 16.066 -3.331 25.326 -3.331z" />
            </g>
            <g>
              <path d="M1083.936 576.968h-62.553v62.559h62.563zm778.419 0H1146.495v62.559h61.766v62.563h-124.325v61.76h156.002v62.563h30.886v62.553h-124.325v61.777h124.325v62.559h-124.325v62.559h61.76v61.766h31.679v62.559h248.648V1013.298h62.563l62.559 62.563v124.325h248.648V982.418l-61.766 -62.559 61.766 -62.559zM1613.707 826.409h-125.122v-62.553h125.122zm-592.33 -124.325h-124.317v61.766h124.325zm62.563 124.325h-124.328v62.563h124.325zm0 124.325H897.052v62.563h186.887zm-90.278 125.122v61.766h93.443v-61.766zm186.887 61.766h-62.563v62.563h62.563z" />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}
