import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import Divider from '@mui/material/Divider';
import { FoodItem } from '../../types/Log';

type QuickAddProps = {
  handleClose: () => void;
  item: FoodItem | undefined;
  addItem: (item: FoodItem) => void;
  updateItem: (item: FoodItem) => void;
  deleteItem: (() => void) | undefined;
};

export default function QuickAdd({
  handleClose,
  addItem,
  deleteItem,
  updateItem,
  item,
}: QuickAddProps) {
  const [name, setName] = useState('');
  const [protein, setProtein] = useState('');
  const [carbohydrates, setCarbohydrates] = useState('');
  const [fat, setFat] = useState('');
  const [calories, setCalories] = useState(0);

  useEffect(() => {
    setCalories(
      (parseInt(carbohydrates, 10) || 0) * 4
      + (parseInt(protein, 10) || 0) * 4
      + (parseInt(fat, 10) || 0) * 9,
    );
  }, [protein, carbohydrates, fat]);

  useEffect(() => {
    if (item) {
      setName(item.name);
      setProtein(item.serving_protein.toString());
      setCarbohydrates(item.serving_carbohydrates.toString());
      setFat(item.serving_fat.toString());
    }
  }, [item]);

  const handleAdd = () => {
    const currDate = new Date();
    const newItem: FoodItem = {
      name,
      time: `${currDate.getHours()}:${currDate.getMinutes()}`,
      type: 'quickadd',
      amount: 1,
      amount_unit: 'each',
      serving_size: 1,
      serving_size_unit: 'each',
      serving_calories: 0,
      serving_carbohydrates: parseInt(carbohydrates, 10),
      serving_protein: parseInt(protein, 10),
      serving_fat: parseInt(fat, 10),
    };
    newItem.serving_calories = newItem.serving_carbohydrates * 4
      + newItem.serving_fat * 9
      + newItem.serving_protein * 4;
    if (item) {
      updateItem(newItem);
    } else {
      addItem(newItem);
    }
    handleClose();
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          Quick Add
        </Typography>
        <Grid container spacing={2} sx={{ p: 1 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Protein"
              value={protein}
              onChange={(e) => setProtein(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">g</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Carbohydrates"
              value={carbohydrates}
              onChange={(e) => setCarbohydrates(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">g</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Fat"
              value={fat}
              onChange={(e) => setFat(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">g</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Calories: </strong>
              {calories}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <Divider sx={{ marginBottom: 2 }} />
      <CardActions sx={{ p: 2, paddingTop: 0 }}>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Button
            sx={{ marginRight: 1 }}
            size="small"
            color="error"
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>
          {deleteItem !== undefined ? (
            <Button
              size="small"
              color="error"
              variant="contained"
              onClick={deleteItem}
            >
              Delete
            </Button>
          ) : null}
          <Box sx={{ flexGrow: 2 }} />
          <Button
            sx={{ float: 'right' }}
            size="small"
            variant="contained"
            onClick={handleAdd}
          >
            {item === undefined ? 'Add' : 'Update'}
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
}
