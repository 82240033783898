import { useRef } from 'react';
// import { QuaggaJSResultObject } from '@ericblade/quagga2';
import Box from '@mui/material/Box';
import Barcode from '../../components/Barcode';

type BarcodeScannerProps = {
  handleUPC: (upc: string) => void;
};

export default function BarcodeScanner({ handleUPC }: BarcodeScannerProps) {
  const scannerRef = useRef(null);

  return (
    <Box sx={{
      '& video': {
        width: '100%',
      },
      maxHeight: '90vh',
    }}
    >
      <div ref={scannerRef} style={{ position: 'relative' }}>
        <canvas
          className="drawingBuffer"
          style={{
            position: 'absolute',
            top: '0px',
            width: '100%',
            // left: '0px',
            // height: '480',
            // width: '640',
          }}
          width="640"
          height="480"
        >
          &nbsp;
        </canvas>
        <Barcode
          scannerRef={scannerRef}
          onDetected={(result) => (result && result.codeResult.code)
            && handleUPC(result.codeResult.code)}
          onScannerReady={() => null}
          cameraId=""
          facingMode="environment"
        />
      </div>
    </Box>
  );
}
