import { useState, useEffect } from 'react';
import { FoodItem } from '../types/Log';
import titleCase from './titleCase';

type USDANutrient = {
  nutrientName: string;
  nutrientId: number;
  value: number;
  unitName: string;
};

type USDAFoods = {
  fdcId: number;
  description: string;
  brandName?: string;
  foodNutrients: USDANutrient[];
  gtinUpc?: string;
  servingSize?: number;
  servingSizeUnit?: string;
  dataType: 'Foundation' | 'Branded';
};

type USDAResponse = {
  totalHits: number;
  foods: USDAFoods[];
};

function formatServingSizeUnit(unit: string) {
  const unitLowercase = unit.toLowerCase();
  if (unitLowercase === 'g' || unitLowercase === 'GRM') {
    return 'g';
  }
  if (unitLowercase === 'ml' || unitLowercase === 'ML') {
    return 'ml';
  }
  return unit;
}

async function makeUSDAQuery(searchTerm: string): Promise<FoodItem[]> {
  const response = await fetch(
    'https://api.nal.usda.gov/fdc/v1/foods/search?api_key=kk5dXPmLzVeAAimGOcllEcWBeZOG1eNrbgwpObzd',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        pageSize: 50,
        query: searchTerm,
        dataType: ['Foundation', 'Branded'],
      }),
    },
  );
  const results: USDAResponse = await response.json();
  if (results.totalHits === 0) {
    return [];
  }
  return (results.foods || []).map((food) => {
    let cal = 0;
    let pro = 0;
    let car = 0;
    let fat = 0;
    food.foodNutrients.forEach((nutrient) => {
      if (nutrient.nutrientId === 1008) {
        cal = Math.round((nutrient.value / 100) * (food.servingSize || 100));
      }
      if (nutrient.nutrientId === 1003) {
        if (
          food.servingSizeUnit === 'g'
          || food.servingSizeUnit === 'GRM'
          || food.servingSizeUnit === 'ml'
          || food.servingSizeUnit === 'ML'
          || food.servingSizeUnit === undefined
        ) {
          pro = Math.round((nutrient.value / 100) * (food.servingSize || 100));
        }
      }
      if (nutrient.nutrientId === 1005) {
        if (
          food.servingSizeUnit === 'g'
          || food.servingSizeUnit === 'GRM'
          || food.servingSizeUnit === 'ml'
          || food.servingSizeUnit === 'ML'
          || food.servingSizeUnit === undefined
        ) {
          car = Math.round((nutrient.value / 100) * (food.servingSize || 100));
        }
      }
      if (nutrient.nutrientId === 1004 || nutrient.nutrientId === 1085) {
        if (
          food.servingSizeUnit === 'g'
          || food.servingSizeUnit === 'GRM'
          || food.servingSizeUnit === 'ml'
          || food.servingSizeUnit === 'ML'
          || food.servingSizeUnit === undefined
        ) {
          fat = Math.round((nutrient.value / 100) * (food.servingSize || 100));
        }
      }
    });
    const currDate = new Date();
    return {
      time: `${currDate.getHours()}:${currDate.getMinutes()}`,
      amount: food.servingSize || 100,
      amount_unit: formatServingSizeUnit(food.servingSizeUnit || 'g'),
      brandName: food.brandName,
      name: titleCase(food.description),
      upc: food.gtinUpc,
      fdcId: food.fdcId,
      serving_size: food.servingSize || 100, // Default it to 100g
      serving_size_unit:
        formatServingSizeUnit(food.servingSizeUnit || 'g'),
      serving_calories: cal,
      serving_carbohydrates: car,
      serving_fat: fat,
      serving_protein: pro,
      source: 'usda',
      type: 'search',
      usda_type: food.dataType,
    };
  });
}

export default function useFoodSearch() {
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [searchResults, setSearchResults] = useState<FoodItem[]>([]);
  const [upcResult, setUpcResult] = useState<FoodItem | undefined>();
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {
    if (searchTerm === undefined) {
      setSearchResults([]);
    }
  }, [searchTerm]);

  const startSearch = (currSearchTerm?: string) => {
    const term = currSearchTerm || searchTerm;
    if (term === undefined) {
      return;
    }
    setLoading(true);
    makeUSDAQuery(term).then((result) => {
      if (result.length === 0) {
        setNoResults(true);
      }
      result.forEach((item) => {
        if (item.upc === term) {
          setUpcResult(item);
        }
      });
      setSearchResults(result);
      setLoading(false);
    });
  };

  const reset = () => {
    setSearchTerm(undefined);
    setLoading(false);
    setNoResults(false);
    setSearchResults([]);
    setUpcResult(undefined);
  };

  return {
    searchTerm,
    setSearchTerm,
    searchResults,
    startSearch,
    upcResult,
    loading,
    noResults,
    reset,
  };
}
