import Fab from '@mui/material/Fab';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import TodayIcon from '@mui/icons-material/Today';

dayjs.extend(weekOfYear);

interface DateDivProps {
  day: string;
  date: string;
  today?: boolean;
  onClick: () => void;
  selected?: boolean;
}

type DateHeaderProps = {
  selectedDay: string;
  setSelectedDay: (day: string) => void;
};

function DateDiv({
  day,
  date,
  onClick,
  today = false,
  selected = false,
}: DateDivProps) {
  // const { day, date, selected } = props;
  const theme = useTheme();
  if (selected) {
    return (
      <button
        type="button"
        style={{
          textAlign: 'center',
          background: 'black',
          color: 'white',
          borderRadius: '8px',
          borderColor: 'black',
          borderWidth: '1px',
          margin: '-8px',
          boxShadow: '4px 4px 8px grey',
          padding: '0',
          cursor: 'pointer',
        }}
        onClick={() => onClick()}
      >
        <div
          style={{
            padding: '12px 12px 0 12px',
          }}
        >
          <Typography>
            <strong>{day}</strong>
          </Typography>
        </div>
        <div
          style={{
            padding: '6px 12px 6px 12px',
            backgroundColor: theme.palette.primary.light,
            color: 'black',
            borderBottomLeftRadius: '7px',
            borderBottomRightRadius: '7px',
          }}
        >
          <Typography>
            <strong>{today ? `${date}*` : date}</strong>
          </Typography>
        </div>
      </button>
    );
  }
  return (
    <button
      type="button"
      onClick={() => onClick()}
      style={{
        textAlign: 'center',
        color: 'white',
        borderWidth: '0',
        backgroundColor: 'unset',
        cursor: 'pointer',
      }}
    >
      <div>
        <Typography>
          <small>{day}</small>
        </Typography>
      </div>
      <div>
        <Typography>
          {today ? (
            <strong>
              {date}
              *
            </strong>
          ) : date}
        </Typography>
      </div>
      {selected}
    </button>
  );
}

function generateDates(
  selectedDay: string,
  setSelectedDay: (day: string) => void,
) {
  const currDate = dayjs(selectedDay);
  const dayOfWeek = currDate.day();

  const generateDateDiv = (index: number) => (
    <DateDiv
      day={currDate
        .add(index - dayOfWeek, 'day')
        .format('ddd')
        .toLowerCase()}
      date={currDate
        .add(index - dayOfWeek, 'day')
        .date()
        .toString()}
      selected={currDate.add(index - dayOfWeek, 'day').isSame(currDate, 'day')}
      today={currDate
        .add(index - dayOfWeek, 'day')
        .isSame(dayjs(Date.now()), 'day')}
      onClick={() => setSelectedDay(currDate.add(index - dayOfWeek, 'day').toString())}
    />
  );
  return (
    <>
      {generateDateDiv(0)}
      {generateDateDiv(1)}
      {generateDateDiv(2)}
      {generateDateDiv(3)}
      {generateDateDiv(4)}
      {generateDateDiv(5)}
      {generateDateDiv(6)}
    </>
  );
}

function DateHeader({ selectedDay, setSelectedDay }: DateHeaderProps) {
  const theme = useTheme();

  const weekAhead = () => {
    const currDate = dayjs(selectedDay);
    setSelectedDay(currDate.add(7, 'day').toString());
  };

  const weekBack = () => {
    const currDate = dayjs(selectedDay);
    setSelectedDay(currDate.subtract(7, 'day').toString());
  };

  return (
    <div style={{ padding: theme.spacing(2), paddingBottom: theme.spacing(3) }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: theme.palette.primary.dark,
          borderRadius: '8px',
        }}
      >
        <div style={{ marginRight: '-8px' }}>
          <Fab
            sx={{ marginLeft: '-8px' }}
            size="small"
            color="primary"
            aria-label="last week"
            onClick={() => weekBack()}
          >
            &lt;
          </Fab>
        </div>
        {generateDates(selectedDay, setSelectedDay || (() => null))}
        <div style={{ marginLeft: '-8px' }}>
          <div style={{ marginRight: '-8px' }}>
            <Fab
              size="small"
              color="primary"
              aria-label="next week"
              onClick={() => weekAhead()}
            >
              &gt;
            </Fab>
          </div>
        </div>
      </div>
      <Collapse
        in={!dayjs(selectedDay).isSame(dayjs(Date.now()), 'day')}
        onClick={() => setSelectedDay(Date())}
        sx={{
          cursor: 'pointer',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            zIndex: -1,
            backgroundColor: theme.palette.background.paper,
            width: 'fit-content',
            marginLeft: 2,
            marginTop: -2,
            marginBottom: -2,
            paddingTop: 3.5,
            paddingBottom: 1,
            px: 1,
            borderColor: theme.palette.primary.dark,
            borderWidth: '2px',
            borderStyle: 'solid',
            borderRadius: '8px',
          }}
        >
          <TodayIcon
            sx={{ verticalAlign: 'bottom', color: theme.palette.primary.dark }}
          />
          <Typography variant="caption">Today</Typography>
        </Box>
      </Collapse>
    </div>
  );
}

export default DateHeader;
