import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';

type MacroSliderProps = {
  current: number;
  goal?: number;
};

function MacroSlider({ current, goal }: MacroSliderProps) {
  // Adjust for 120% of goal is full width
  let adjPercentage = ((current / (goal || current)) * 100) / 1.2;
  if (adjPercentage > 100) {
    adjPercentage = 100;
  }
  return (
    <div style={{ position: 'relative', paddingTop: '4px' }}>
      <Box sx={{ backgroundColor: grey[300], height: '10px' }}>
        <Box
          sx={{
            width: `${adjPercentage}%`,
            backgroundColor: (theme) => theme.palette.primary.main,
            height: '100%',
          }}
        />
      </Box>
      {goal === undefined ? null : (
        <div
          style={{
            backgroundColor: grey[600],
            height: '18px',
            width: '1px',
            position: 'absolute',
            top: 0,
            left: '80%',
          }}
        />
      )}
    </div>
  );
}

export default MacroSlider;
